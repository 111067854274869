@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../settings/z-index" as z;

// =============================================================================
// Block
// =============================================================================
.c-article-preview {
	display: flex;
	flex-direction: column;
}

// =============================================================================
// Elements
// =============================================================================
.c-article-preview__image-wrapper {
	position: relative;
}

.c-article-preview__image {
	width: 100%;
	height: auto;
	aspect-ratio: 16/9;

	margin-bottom: _system.space(2);

	border-radius: borders.$radius-default;
}

.c-article-preview__event-date {
	position: absolute;
	top: _system.space(4);
	right: _system.space(4);
	z-index: z.$foreground;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: _system.space(2) _system.space(4);

	background-color: _system.color(primary, pink);
	color: _system.color(primary, orange);
	border-radius: borders.$radius-default;
}

.c-article-preview__event-day {
	@include _system.typo("h2");
}

.c-article-preview__event-month {
	@include _system.typo("label");
}

.c-article-preview__title {
	@include _system.typo(h3);

	color: _system.color(primary, green);

	transition: color 0.3s ease-in-out;
}

.c-article-preview__description {
	@include _system.typo(paragraph, base);

	margin-top: _system.space(5);

	color: _system.color(neutrals, 10);
}

.c-article-preview__author {
	margin-top: _system.space(3);
}

.c-article-preview__label {
	@include _system.typo(label, base);

	display: block;

	color: _system.color(primary, orange);
}

.c-article-preview__date {
	@include _system.typo(paragraph, base);

	display: block;

	color: _system.color(neutrals, 20);
}

.c-article-preview__circle {
	margin-top: _system.space(1);

	color: _system.color(primary, orange);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-article-preview--highlighted {
	display: flex;
	flex-direction: column;

	@include _system.at("7") {
		flex-direction: row;
	}

	.c-article-preview__title {
		@include _system.typo(h1);
	}
}

// =============================================================================
// States
// =============================================================================
.c-article-preview {
	&:hover {
		.c-article-preview__title {
			color: _system.color(primary, orange);
		}
	}
}
