@use "@littlemissrobot/sass-system" as _system;
@use "../settings/shadow" as shadow;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-categories {
	position: relative;
}

// =============================================================================
// Elements
// =============================================================================
.c-categories__inner {
	align-items: flex-end;
	overflow: hidden;

	background-color: _system.color(neutrals, 100);
	box-shadow: shadow.$default;
	border-radius: borders.$radius-default;
}

.c-categories__item,
.c-categories__cta {
	padding: _system.space(5) _system.space(4);

	@include _system.at("9") {
		padding: _system.space(6) _system.space(8);
	}
}

.c-categories__title {
	@include _system.typo(label, small);

	display: block;
	margin-bottom: _system.space(1);
	padding-top: _system.space(4);
	padding-left: _system.space(4);

	color: _system.color(neutrals, 50);

	@include _system.at("9") {
		padding-left: _system.space(6);
	}
}

.c-categories__item {
	position: relative;

	&::after {
		content: "";

		position: absolute;
		top: 0;
		right: 0;

		display: none;
		width: 100%;
		height: _system.rem(1px);

		background-image: linear-gradient(
			to right,
			transparent 50%,
			_system.color(primary, orange) 50%
		);
		background-size: _system.rem(10px) 100%;

		@include _system.at("9") {
			top: 0;
			left: 0;

			width: _system.rem(1px);
			height: 100%;

			background-image: linear-gradient(
				to bottom,
				transparent 50%,
				_system.color(primary, orange) 50%
			);
			background-size: 100% _system.rem(10px);
		}
	}

	& + .c-categories__item {
		&::after {
			display: block;
		}

		&:nth-child(even) {
			&::after {
				@include _system.at("4") {
					display: none;
				}

				@include _system.at("9") {
					display: block;
				}
			}
		}
	}
}

.c-categories__cta {
	display: flex;
	// align-items: flex-end;
	align-self: stretch;

	background-color: _system.color(primary, orange);

	@include _system.at("7") {
		padding-top: _system.space(15);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-categories--overlap {
	margin-top: _system.space(-10);

	@include _system.at("9") {
		margin-top: _system.space(-25);
	}
}
