@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-icon-circle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: _system.rem(36px);
	height: _system.rem(36px);

	border-radius: borders.$radius-round;
	background-color: _system.color(opacity, orange-20);

	@include _system.at("9") {
		width: _system.rem(50px);
		height: _system.rem(50px);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-icon-circle__image {
	width: _system.rem(20px);
	height: _system.rem(20px);

	@include _system.at("9") {
		width: _system.rem(25px);
		height: _system.rem(25px);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-icon-circle--white {
	background-color: _system.color(opacity, white-20);
}

.c-icon-circle--small {
	width: _system.rem(24px);
	height: _system.rem(24px);

	@include _system.at("9") {
		width: _system.rem(36px);
		height: _system.rem(36px);
	}

	.c-icon-circle__image {
		width: _system.rem(12px);
		height: _system.rem(12px);

		@include _system.at("9") {
			width: _system.rem(20px);
			height: _system.rem(20px);
		}
	}
}

.c-icon-circle--ghost {
	width: unset;
	height: unset;

	background-color: transparent;

	@include _system.at("9") {
		width: unset;
		height: unset;
	}
}
