@use "@littlemissrobot/sass-system" as _system;
@use "../settings/z-index" as z;

// prettier-ignore
.u-rel {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		position: relative;
	}
}

.u-sticky {
	@include _system.generate-default(
		(
			default: true,
			at: (
				"9",
			),
			to: (),
		)
	) {
		position: sticky;
		top: _system.space(10);
	}
}

// prettier-ignore
.u-fix {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		position: fixed;
		top: 0;
		left: 0;
	}
}

// prettier-ignore
.u-abs {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		position: absolute;
		top: 0;
		left: 0;
	}
}

// prettier-ignore
.u-abs-center {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);
	}
}

// prettier-ignore
.u-abs-center-y {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		position: absolute;
		top: 50%;

		transform: translateY(-50%);
	}
}

// prettier-ignore
.u-abs-center-x {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		position: absolute;
		left: 50%;

		transform: translateX(-50%);
	}
}

// prettier-ignore
.u-z {
	@include _system.generate-value((
		value: (),
		at: (),
		to: (),
	)) using ($item) {
		z-index: $item;
	}
}

// prettier-ignore
.u-z-foreground {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		z-index: z.$z_foreground;
	}
}

// prettier-ignore
.u-z-base {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		z-index: z.$z_base;
	}
}

// prettier-ignore
.u-z-background {
	@include _system.generate-default((
		default: false,
		at: (),
		to: (),
	)) {
		z-index: z.$z_background;
	}
}
