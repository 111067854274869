@use "@littlemissrobot/sass-system" as _system;

/**
 * 1. :root selector equalizes `<html>`, but has higher specificity to not have
 *    it easily overwritten.
 * 2. Fix for 100vh issue on safari mobile browsers
 * 3. Ensure the page always fills up the entire height of the viewport.
 * 4. Force vertical scrollbars to always be visible to prevent awkward jumps
 *    when navigating between pages that do/do not have content to produce
 *    scrollbars naturally.
 * 5. Makes sure that 1rem = 16px, all rem scale along this percentage. Use
 *    _system.rem(16px) to output rem correctly.
 */
:root /* 1 */ {
	/* stylelint-disable-next-line value-no-vendor-prefix */
	height: -webkit-fill-available; // 2
	min-height: 100%; // 3
	overflow-y: scroll; // 4

	font-size: 100%; // 5
}

/**
 * 1. Fix for 100vh issue on safari mobile browsers, use min-height 100vh as
 *    fallback
 * 2. Hide horizontal scrollbar, REMOVE IF POSITION: STICKY SHOULD BE USED
 */
body {
	// @include _system.typo(p);

	min-height: 100vh; // 1
	/* stylelint-disable-next-line value-no-vendor-prefix */
	min-height: -webkit-fill-available;

	@include _system.to("9") {
		overflow-x: hidden; // 2
	}

	// color: _system.color(text);
	// background-color: _system.color(background);

	/* stylelint-disable selector-no-qualifying-type */
	&.has-scroll-disabled {
		position: fixed;
		right: 0;
		left: 0;

		width: 100%;

		pointer-events: none;
	}
	/* stylelint-enable selector-no-qualifying-type */
}
