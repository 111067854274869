@use "@littlemissrobot/sass-system" as _system;
@use "../settings/shadow" as shadow;
@use "../settings/borders" as borders;
@use "../settings/z-index" as z;

// =============================================================================
// Block
// =============================================================================
.c-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;

	opacity: 0;

	pointer-events: none;
}

// =============================================================================
// Elements
// =============================================================================
.c-overlay__backdrop {
	position: absolute;
	top: 0;
	left: 0;
	z-index: z.$base;

	width: 100%;
	height: 100%;

	background-color: _system.color(neutrals, 10);
	opacity: 0.3;
}

.c-overlay__close {
	position: absolute;
	top: _system.rem(24px);
	right: _system.rem(24px);
	z-index: z.$foreground;

	display: block;
	width: _system.rem(24px);
	min-width: _system.rem(24px);
	height: _system.rem(24px);
	min-height: _system.rem(24px);
	margin-top: _system.rem(3px);
}

.c-overlay__line {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 100%;
	height: _system.rem(2px);

	background-color: _system.color(neutrals, 10);

	transform: translateX(-50%) translateY(-50%);
	transform-origin: center;
	transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1),
		background-color 0.3s ease-in-out;
}

.c-overlay__content-wrapper {
	position: relative;
	z-index: z.$foreground;

	height: 100%;
	overflow: auto;
	padding-top: _system.space(10);
	padding-bottom: _system.space(10);
}

.c-overlay__content {
	position: relative;

	padding: _system.space(10) _system.space(10);

	background-color: _system.color(neutrals, 100);
	box-shadow: shadow.$default;
	border-radius: borders.$radius-default;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-overlay__line--first {
	transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.c-overlay__line--second {
	transform: translateX(-50%) translateY(-50%) rotate(135deg);
}

// =============================================================================
// States
// =============================================================================
.c-overlay__close {
	&:hover {
		.c-overlay__line {
			transform: translateX(-50%) translateY(-50%) rotate(0deg);
		}
	}
}

.c-overlay {
	&.is-open {
		opacity: 1;

		pointer-events: auto;
	}
}
