@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-category {
	display: flex;
	flex-direction: column;
	flex: 1;
}

// =============================================================================
// Elements
// =============================================================================
.c-category__title {
	@include _system.typo(h4, base);

	margin-bottom: _system.space(2);

	color: _system.color(primary, green);

	transition: color 0.2s ease-in-out;
}

.c-category__description {
	@include _system.typo(paragraph, small);

	color: _system.color(neutrals, 20);
}

.c-category__icon {
	margin-bottom: _system.space(2);

	transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);

	@include _system.at("9") {
		margin-bottom: _system.space(4);
	}
}

.c-category__arrow {
	align-self: flex-end;
	margin-top: auto;
	padding-top: _system.space(3);

	transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-category--highlight {
	.c-category__title,
	.c-category__description {
		color: _system.color(neutrals, 100);
	}
}

.c-category--link {
	&:hover {
		.c-category__title {
			color: _system.color(primary, orange);
		}

		.c-category__arrow {
			transform: translateX(_system.rem(5px));
		}

		.c-category__icon-wrapper {
			transform: scale(0.9);
		}
	}

	&.c-category--highlight {
		&:hover {
			.c-category__title {
				color: _system.color(primary, pink);
			}
		}
	}
}
