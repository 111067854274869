@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-contact {
	padding: _system.space(16) 0 _system.space(30);

	background-image: url("/assets/images/background-lines.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right center;
}

// =============================================================================
// Elements
// =============================================================================
.c-contact__title {
	@include _system.typo(h1);

	color: _system.color(primary, green);
}

.c-contact__subtitle {
	@include _system.typo(label, base);

	color: _system.color(primary, orange);
}

.c-contact__list {
	margin-top: _system.space(10);
}

.c-contact__list-item {
	& + .c-contact__list-item {
		margin-top: _system.space(6);
	}
}

.c-contact__description {
	@include _system.typo(paragraph, base);

	margin-top: _system.space(4);

	color: _system.color(neutrals, 10);
}

.c-contact__buttons {
	margin-top: _system.space(10);
}

.c-contact__button {
	& + .c-contact__button {
		margin-left: _system.space(5);
	}
}

.c-contact__submit {
	border: none;
}

// =============================================================================
// States
// =============================================================================
.c-contact__section {
	display: none;

	&.is-active {
		display: block;
	}
}

.c-contact__list {
	display: none;

	&.is-active {
		display: block;
	}
}
