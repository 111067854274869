// =============================================================================
// Block
// =============================================================================
.c-picture {
	max-width: 100%;
	// height: 100%;
	max-height: 100%;
	overflow: hidden;
}

// =============================================================================
// Elements
// =============================================================================
.c-picture__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-picture--cover {
	height: 100%;

	.c-picture__image {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
