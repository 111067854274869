@use "@littlemissrobot/sass-system" as _system;

a,
button {
	display: inline-block;
}

/**
 * 1. Remove default styling
 * 2. Force pointer cursor onto button
 */
button {
	padding: 0; // 1

	border: none; // 1
	background-color: transparent; // 1

	cursor: pointer; // 2
}

a {
	// color: _system.color();

	transition: color 0.2s ease-in-out;

	&:hover {
		// color: _system.color();
	}
}

// A elements with class indicate custom styling
a[class] {
	text-decoration: none; // 1
}

// A elements that don't have a class get default styles
a:not([class]) {
	text-decoration-skip-ink: auto;
}
