@use "@littlemissrobot/sass-system" as _system;
@use "../utils/reset" as reset;

// =============================================================================
// Block
// =============================================================================
.c-icon {
	@include reset.reset-link();

	display: inline-flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	width: 100%;
	height: 100%;

	// Style the included SVG icon by directly selecting the SVG.
	& > svg {
		vertical-align: middle;
		width: inherit;
		height: inherit;

		fill: currentColor;
	}
}
