@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
$color: (
	value: (),
	at: (),
	to: (),
);

// prettier-ignore
$background-color: (
	value: (),
	at: (),
	to: (),
);

.u-color-primary-orange {
	color: _system.color(primary, orange);
}

.u-color-primary-green {
	color: _system.color(primary, green);
}
