@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-link {
	@include _system.typo(link, base);

	color: _system.color(primary, orange);
	background-image: linear-gradient(
		transparent calc(100% - 1px),
		_system.color(primary, orange) 1px
	);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: right;

	transition: background-size 0.2s ease-in-out;

	&:hover {
		background-size: 0% 100%;
	}
}
