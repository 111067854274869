@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Elements
// =============================================================================
.c-split__content,
.c-split__visual {
	@include _system.at("7") {
		flex: 1;
	}
}

.c-split__content {
	width: 100%;
	padding: _system.space(5) 0;

	@include _system.at("7") {
		padding: _system.space(20) 0;
	}
}

.c-split__visual {
	position: relative;

	@include _system.to("7") {
		height: _system.rem(250px);
	}

	@include _system.at("7") {
		min-height: _system.rem(350px);
	}

	@include _system.at("9") {
		min-height: _system.rem(500px);
	}
}

.c-split__label {
	@include _system.typo(label, small);

	color: _system.color(neutrals, 50);
}

.c-split__title {
	@include _system.typo(h1);

	color: _system.color(primary, green);
}

.c-split__text {
	margin-top: _system.space(5);

	color: _system.color(neutrals, 10);
}

.c-split__button {
	margin-top: _system.space(5);

	@include _system.at("7") {
		margin-top: _system.space(10);
	}
}

.c-split__image {
	@include _system.at("7") {
		position: absolute;
		top: 0;
		left: 0;

		object-fit: cover;
		object-position: center;
		width: 100%;
		max-width: 100%;
		height: 100%;
	}
}
