@use "@littlemissrobot/sass-system" as _system;
@use "../utils/reset" as reset;
@use "../settings/borders" as borders;

$_hamburger-height: _system.rem(17px);
$_hamburger-width: _system.rem(24px);
$_bar-height: _system.rem(3px);
$_middle: _system.rem(9px);

// =============================================================================
// Block
// =============================================================================
.c-hamburger {
	@include reset.reset-button();
}

// =============================================================================
// Elements
// =============================================================================
.c-hamburger__inner {
	position: relative;

	display: block;
	width: $_hamburger-width;
	height: $_hamburger-height;
}

.c-hamburger__top-bar,
.c-hamburger__middle-bar,
.c-hamburger__bottom-bar {
	position: absolute;
	left: 0;

	display: block;
	width: 100%;
	height: $_bar-height;

	border-radius: borders.$radius-default;
	background-color: _system.color(primary, green);

	transition: opacity 0.2s ease-in-out, transform 0.3s ease-in-out;
	transform-origin: center;
}

.c-hamburger__top-bar {
	top: 0;
}

.c-hamburger__middle-bar {
	top: calc(50% - (#{$_bar-height} * 0.5));
}

.c-hamburger__bottom-bar {
	bottom: 0;
}

// =============================================================================
// States
// =============================================================================
.c-hamburger {
	&.is-cross {
		.c-hamburger__top-bar {
			opacity: 0;

			transform: translateY($_middle);
		}

		.c-hamburger__bottom-bar {
			opacity: 0;

			transform: translateY(-$_middle);
		}

		.c-hamburger__middle-bar--left-diagonal {
			transform: rotate(45deg);
		}

		.c-hamburger__middle-bar--right-diagonal {
			transform: rotate(-45deg);
		}
	}
}
