@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
.u-text-left {
	@include _system.generate-default((
		default: false,
		at: (),
		to: ()
	)) {
		text-align: left;
	}
}

// prettier-ignore
.u-text-center {
	@include _system.generate-default((
		default: true,
		at: (),
		to: ()
	)) {
		text-align: center;
	}
}

// prettier-ignore
.u-text-right {
	@include _system.generate-default((
		default: false,
		at: (),
		to: ()
	)) {
		text-align: right;
	}
}
