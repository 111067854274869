@use "@littlemissrobot/sass-system" as _system;
@use "../settings/z-index" as z;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-header {
	position: relative;
	z-index: z.$header;

	background-color: _system.color(neutrals, 100);
	border-bottom: borders.$default;

	@include _system.to("9") {
		padding: _system.space(6) 0;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-header__nav {
	width: 100%;
}

.c-header__logo {
	@include _system.at("9") {
		width: auto;
		margin-right: _system.space(7);
	}
}

.c-header__nav-list {
	flex: 1;

	@include _system.to("9") {
		display: none;
	}
}

.c-header__hamburger {
	@include _system.at("9") {
		display: none;
	}
}
