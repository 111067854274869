@use "@littlemissrobot/sass-system" as _system;

$spacing: (
	default: 15,
	"9": 25,
);

/**
 * Used to differentiate blocks/section/regions on a page from each other using
 * spacing on top and on the bottom.
 */

// prettier-ignore
.u-section {
	@include _system.generate-default-with-breakpoints((
		default: true,
		at: (),
		to: (),
	), $spacing) using ($item) {
		$space: _system.space($item);

		padding-top: $space;
		padding-bottom: $space;
	}
}

// prettier-ignore
.u-section-top {
	@include _system.generate-default-with-breakpoints((
		default: false,
		at: (),
		to: (),
	), $spacing) using ($item) {
		$space: _system.space($item);

		padding-top: $space;
	}
}

// prettier-ignore
.u-section-bottom {
	@include _system.generate-default-with-breakpoints((
		default: false,
		at: (),
		to: (),
	), $spacing) using ($item) {
		$space: _system.space($item);

		padding-bottom: $space;
	}
}
