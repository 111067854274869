@use "@littlemissrobot/sass-system" as _system;
@use "../settings/shadow" as shadow;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-practicals {
	padding: _system.space(7);

	background-color: _system.color(neurtrals, 100);
	border-top: borders.$thick-green;
	border-radius: borders.$radius-default;
	box-shadow: shadow.$default;

	@include _system.at("9") {
		position: sticky;
		top: _system.space(5);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-practicals__title {
	color: _system.color(primary, green);
}

.c-practicals__label {
	@include _system.typo(label, small);

	display: block;

	color: _system.color(neutrals, 50);
}

.c-practicals__item {
	padding: _system.space(4) 0;

	& + .c-practicals__item {
		border-top: borders.$default;
	}
}
