@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-pill {
	position: relative;

	display: inline-block;

	cursor: pointer;
}

// =============================================================================
// Elements
// =============================================================================
.c-pill__label {
	display: flex;
	padding: _system.convert(6px) _system.space(4);

	background-color: _system.color(neutrals, 100);
	border: borders.$default-orange;
	border-radius: 100vmax;

	transition: background-color 0.2s ease-in-out;

	cursor: pointer;
}

.c-pill__text {
	@include _system.typo(paragraph, base);

	margin-left: _system.space(2);

	transition: color 0.2s ease-in-out;
}

.c-pill__icon-container {
	height: _system.space(5);
	margin: _system.space(1);
	padding-right: _system.space(2);

	border-right: borders.$width-default solid _system.color(opacity, orange-40);

	transition: border-color 0.2s ease-in-out;
}

.c-pill__icon {
	color: _system.color(primary, orange);

	transition: color 0.2s ease-in-out;
}

.c-pill__icon--check {
	display: none;
}

// =============================================================================
// States
// =============================================================================
.c-pill__input:checked + .c-pill__label {
	background-color: _system.color(primary, orange);

	.c-pill__text,
	.c-pill__icon {
		color: _system.color(neutrals, 100);
	}

	.c-pill__icon-container {
		border-color: _system.color(opacity, beige-40);
	}

	.c-pill__icon--check {
		display: block;
	}

	.c-pill__icon--plus {
		display: none;
	}
}

.c-pill__input:not(:checked):hover + .c-pill__label {
	.c-pill__text {
		color: _system.color(primary, orange);
	}

	.c-pill__icon--check {
		display: block;
	}

	.c-pill__icon--plus {
		display: none;
	}
}
