@use "@littlemissrobot/sass-system" as _system;
@use "../settings/shadow" as shadow;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-discipline {
	padding: _system.space(5);

	background-color: _system.color(neutrals, 100);
	box-shadow: shadow.$default;
	border-radius: borders.$radius-default;

	@include _system.at("9") {
		padding: _system.space(8);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-discipline__languages {
	@include _system.typo(label, small);

	padding: _system.space(1) _system.space(2);

	background-color: _system.color(neutrals, 90);
	border-radius: borders.$radius-default;
	color: _system.color(neutrals, 20);
}

.c-discipline__title {
	margin-top: _system.space(2);

	color: _system.color(primary, green);

	@include _system.at("9") {
		margin-top: _system.space(4);
	}
}

.c-discipline__text {
	@include _system.typo(paragraph, base);

	margin-top: _system.space(2);

	color: _system.color(neutrals, 20);
}

.c-discipline__button {
	margin-top: _system.space(4);
}
