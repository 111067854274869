@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-event-hero {
	position: relative;

	overflow: hidden;
	margin-top: _system.space(10);
	margin-bottom: _system.space(10);
}

// =============================================================================
// Elements
// =============================================================================
.c-event-hero__container {
	position: relative;
}

.c-event-hero__image {
	position: relative;

	border-radius: borders.$radius-default;

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;

		display: block;
		width: 100%;
		height: 100%;

		background-color: black;
		opacity: 0.4;

		@include _system.at("9") {
			background-color: transparent;
			background-image: linear-gradient(to right, black, transparent);
		}
	}
}

.c-event-hero__content {
	position: absolute;
	bottom: _system.space(6);
	left: _system.space(6);

	color: _system.color(neutrals, 100);

	@include _system.at("9") {
		bottom: _system.space(15);
		left: _system.space(15);

		max-width: _system.rem(500px);
	}
}

.c-event-hero__label {
	@include _system.typo(label);

	opacity: 0.6;
}
