@use "@littlemissrobot/sass-system" as _system;
@use "../settings/shadow" as shadow;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-price {
	padding: _system.space(5);

	background-color: _system.color(neutrals, 100);
	box-shadow: shadow.$default;
	border-radius: borders.$radius-default;

	@include _system.at("9") {
		padding: _system.space(8);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-price__title {
	color: _system.color(primary, green);
}

.c-price__text {
	@include _system.typo(paragraph, base);

	margin-top: _system.space(2);

	color: _system.color(neutrals, 20);
}

.c-price__button {
	margin-top: _system.space(4);
}
