@use "@littlemissrobot/sass-system" as _system;
@use "../utils/reset" as reset;

// =============================================================================
// wysiwyg
// =============================================================================
.s-wysiwyg {
	p {
		@include _system.typo(paragraph, base);
		color: _system.color(neutrals, 0);

		strong {
			@include _system.typo(paragraph, bold);
		}
	}

	em {
		font-style: italic;
	}

	span {
		@include _system.typo(paragraph, base);
	}

	h1 {
		@include _system.typo(h1);
	}

	h2 {
		@include _system.typo(h2);
	}

	h3 {
		@include _system.typo(h3);
	}

	h4 {
		@include _system.typo(h4);
	}

	// h5 {
	// 	@include _system.typo(h5);
	// }

	// h6 {
	// 	@include _system.typo(h6);
	// }

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: _system.color(primary, green);
	}

	h1 {
		& + h2 {
			margin-top: _system.space(3);
		}

		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(2);
		}
	}

	h2 {
		& + h1 {
			margin-top: _system.space(1);
		}

		& + h3,
		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(2);
		}
	}

	h3 {
		& + h4 {
			margin-top: _system.space(2);
		}

		& + h2,
		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(1);
		}
	}

	h4 {
		& + h5 {
			margin-top: _system.space(2);
		}

		& + h3,
		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(1);
		}
	}

	h5 {
		& + h6 {
			margin-top: _system.space(2);
		}

		& + h4,
		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(1);
		}
	}

	h6 {
		& + h4,
		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(1);
		}
	}

	p,
	ol,
	ul {
		& + p,
		& + ul,
		& + ol {
			margin-top: _system.space(2);
		}

		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			margin-top: _system.space(3);

			@include _system.at("7") {
				margin-top: _system.space(4);
			}

			@include _system.at("12") {
				margin-top: _system.space(5);
			}
		}
	}

	a {
		@include _system.typo(link, base);

		color: inherit;

		transition: color 0.2s ease-in-out;

		&:hover {
			color: _system.color(primary, orange);
		}
	}

	ul {
		margin-left: 0;
		padding-left: 0;

		list-style-position: inside;

		li {
			@include _system.typo(paragraph, base);

			position: relative;

			// stylelint-disable-next-line
			& + li {
				margin-top: _system.space(1);
			}

			&::marker {
				color: _system.color(primary, orange);
			}
		}
	}

	ol {
		@include reset.reset-list();

		li {
			@include _system.typo(paragraph, base);

			counter-increment: number;

			position: relative;

			&::before {
				content: counter(number) ".";

				display: inline-block;
				padding-right: _system.space(1);

				text-align: right;
			}

			// stylelint-disable-next-line
			& + li {
				margin-top: _system.space(1);
			}
		}
	}

	figure {
		margin: _system.space(10) 0;
	}
}

.s-wysiwyg-intro {
	p {
		@include _system.typo(paragraph, bold);
	}
}
