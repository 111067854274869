@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-gallery {
	--swiper-theme-color: #{_system.color(primary, orange)};
}

// =============================================================================
// Elements
// =============================================================================
.c-gallery__content {
	max-width: _system.space(150);
	margin: auto;
}

.c-gallery__title {
	color: _system.color(primary, green);
}

.c-gallery__slider {
	margin-top: _system.space(12);

	&.swiper {
		overflow: visible;
	}
}

.c-gallery__image {
	margin-bottom: _system.space(2);
}

.c-gallery__button {
	padding: _system.space(2);

	border: none;
	background: none;

	transition: transform 0.3s ease-in-out;

	cursor: pointer;
}

.c-gallery__button-icon {
	width: _system.space(6);
	height: _system.space(6);

	color: _system.color(primary, orange);

	transition: color 0.3s ease-in-out;
}

// =============================================================================
// States
// =============================================================================
.c-gallery__button {
	&:hover {
		transform: scale(0.8);

		.c-gallery__button-icon {
			color: _system.color(button, hover);
		}
	}

	&[disabled] {
		opacity: 0.3;

		cursor: not-allowed;
	}
}
