@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../settings/shadow" as shadow;

// =============================================================================
// Settings
// =============================================================================
$radio-dimensions: _system.rem(27px);

// =============================================================================
// Elements
// =============================================================================
.c-radio-button__label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: _system.space(6);

	background-color: _system.color(neutrals, 100);
	border-radius: borders.$radius-default;
	box-shadow: shadow.$default;

	cursor: pointer;

	&:hover > .c-radio-button__circle::before {
		opacity: 0.7;
	}
}

.c-radio-button__text {
	@include _system.typo(h4, base);

	flex: 1;
	margin: 0 _system.space(6);

	color: _system.color(primary, green);
}

.c-radio-button__input {
	&:checked ~ .c-radio-button__label > .c-radio-button__circle::before {
		opacity: 1;
	}

	&:disabled ~ .c-radio-button__label > .c-radio-button__circle::before,
	&:disabled ~ .c-radio-button__label > .c-radio-button__circle {
		opacity: 1;
		background-color: _system.color(neutrals, 100);

		pointer-events: none;
	}

	&:disabled ~ .c-radio-button__label > .c-radio-button__circle::before {
		background-color: _system.color(neutrals, 80);
	}
}

.c-radio-button__circle {
	position: relative;

	display: inline-block;
	width: $radio-dimensions;
	min-width: $radio-dimensions;
	height: $radio-dimensions;
	margin-right: 1rem;

	border: borders.$default;
	border-radius: borders.$radius-round;

	&::before {
		content: "";

		position: absolute;
		top: 50%;
		left: 50%;

		display: block;
		width: 0.6rem;
		height: 0.6rem;

		opacity: 0;
		background-color: _system.color(primary, orange);
		border-radius: 100%;

		transform: translateX(-50%) translateY(-50%);
		transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;
	}
}
