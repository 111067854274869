@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Elements
// =============================================================================
.c-cta__wrapper {
	padding: _system.space(8) _system.space(6);

	background-color: _system.color(primary, pink);
	border-radius: borders.$radius-default;
	background-image: url("/assets/images/cta-background.png");
	background-repeat: no-repeat;
	background-position: right;
	background-size: auto 100%;

	@include _system.at("7") {
		padding: _system.space(18);
	}
}

.c-cta__title {
	@include _system.typo(h1);

	color: _system.color(primary, orange);
}

.c-cta__text {
	margin-top: _system.space(1);
}

.c-cta__button {
	// margin-left: _system.space(10);

	white-space: nowrap;

	@include _system.to("7") {
		margin-top: _system.space(6);
		// margin-left: 0;
	}
}
