@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../settings/z-index" as z;

// =============================================================================
// Block
// =============================================================================
.c-social-button {
	position: relative;

	display: flex;
	justify-content: center;
	align-items: center;
	width: _system.space(10);
	height: _system.space(10);

	border-radius: _system.space(5);
	border: _system.rem(1px) solid _system.color(primary, pink);

	&::after {
		content: "";

		position: absolute;
		top: 0;
		left: 0;
		z-index: z.$background + 1;

		display: block;
		width: 100%;
		height: 100%;

		background-color: _system.color(primary, pink);
		border-radius: _system.space(6);

		transform: scale(0);
		transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);

		@include _system.at("9") {
			border-radius: _system.space(5);
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-social-button__icon {
	z-index: z.$background + 2;

	width: _system.space(6);
	height: _system.space(6);

	color: _system.color(primary, orange);
}

// =============================================================================
// States
// =============================================================================
.c-social-button {
	&:hover {
		&::after {
			transform: scale(1);
		}
	}
}
