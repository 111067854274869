@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-section {
	& + .c-section {
		margin-top: _system.space(15);

		@include _system.at("9") {
			margin-top: _system.space(25);
		}
	}

	&.c-accordeon {
		& + .c-accordeon,
		& + .c-team,
		& + .c-disciplines {
			margin-top: 0;
		}
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-section {
	& + .c-section--small {
		margin-top: _system.space(10);

		@include _system.at("9") {
			margin-top: _system.space(12);
		}
	}
}
