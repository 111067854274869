@use "sass:map";
@use "@littlemissrobot/sass-system" as _system;
@use "@littlemissrobot/sass-container" as _container;

$container-width: map.get(_container.$settings, "max-width");

// =============================================================================
// Block
// =============================================================================
.c-hero {
	position: relative;

	background: _system.color(primary, pink);
	background-image: url("/assets/images/background-map.svg");
	background-repeat: no-repeat;
	background-size: 130% auto;
	background-position: top 0 center;

	@include _system.at("9") {
		background-size: auto, _system.rem(600px) auto;
		background-position: center center;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-hero__image-container {
	position: relative;

	pointer-events: none;

	@include _system.to("9") {
		min-height: _system.rem(258px);
	}

	@include _system.at("9") {
		position: absolute;
		top: 0;
		right: 0;

		width: 100%;
		height: 100%;
	}
}

.c-hero__image-wrapper,
.c-hero__mask-container,
.c-hero__image {
	width: 100%;
	height: 100%;
}

.c-hero__mask-container {
	overflow: hidden;

	transform: translateX(100%);
}

.c-hero__image {
	transform: translateX(-100%);
}

.c-hero__image-wrapper {
	position: absolute;

	// stylelint-disable indentation
	@include _system.at("9") {
		padding-left: calc(
			((100% - #{_system.rem($container-width)}) / 2) + #{(
					_system.rem($container-width) / 12
				) * 5} + #{_system.space(6) * 2}
		);
	}

	@include _system.at("12") {
		padding-left: calc(
			((100% - #{_system.rem($container-width)}) / 2) + #{(
					_system.rem($container-width) / 12
				) * 5} + #{_system.space(8) * 2}
		);
	}
	// stylelint-enable indentation
}

.c-hero__content {
	padding-top: _system.space(8);
	padding-bottom: _system.space(8);

	@include _system.at("9") {
		padding-top: _system.space(25);
		padding-bottom: _system.space(50);
	}
}

.c-hero__title {
	@include _system.typo(pagetitle, base);

	color: _system.color(primary, green);

	strong {
		@include _system.typo(pagetitle, bold);

		color: _system.color(primary, orange);
	}
}

.c-hero__label {
	@include _system.typo(label, base);

	display: block;

	color: _system.color(neutrals, 50);
}

.c-hero__description {
	@include _system.typo(paragraph, base);

	display: block;
	margin-top: _system.space(6);

	color: _system.color(neutrals, 10);
}
