@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../utils/reset" as reset;

// =============================================================================
// Block
// =============================================================================
.c-input {
	display: flex;
	flex-direction: column;
}

// =============================================================================
// Elements
// =============================================================================
.c-input__label {
	@include _system.typo(paragraph, bold);

	color: _system.color(neutrals, 20);
}

.c-input__field {
	@include _system.typo(paragraph, base);

	padding: _system.space(2) _system.space(4);

	border-radius: borders.$radius-default;
	border: borders.$default;
}

// =============================================================================
// States
// =============================================================================
.c-input__field {
	&:focus {
		outline: none;
		border: borders.$default-orange;
	}
}
