@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-footer {
	margin-top: _system.space(15);
	padding: _system.space(10) 0;

	border-top: _system.space(2) solid _system.color(primary, beige);

	@include _system.at("9") {
		margin-top: _system.space(25);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-footer__contact {
	position: relative;

	padding: _system.space(4) _system.space(0);

	@include _system.at("9") {
		padding: _system.space(0) _system.space(20);
	}

	&::after {
		content: "";

		position: absolute;
		top: 0;
		right: 0;

		width: 100%;
		height: _system.rem(1px);

		background-image: linear-gradient(
			to right,
			transparent 50%,
			_system.color(primary, orange) 50%
		);
		background-size: _system.rem(10px) 100%;

		@include _system.at("9") {
			top: 0;
			left: 0;

			width: _system.rem(1px);
			height: 100%;

			background-image: linear-gradient(
				to bottom,
				transparent 50%,
				_system.color(primary, orange) 50%
			);
			background-size: 100% _system.rem(10px);
		}
	}
}

.c-footer__title {
	@include _system.typo(h3);

	color: _system.color(primary, green);
}

.c-footer__contact-links {
	margin-top: _system.space(3);
}

.c-footer__contact-link {
	@include _system.typo(small, base);
}

.c-footer__contact-text {
	@include _system.typo(small, base);

	color: _system.color(primary, green);
}

.c-footer__contact-button {
	margin-top: _system.space(6);
}

.c-footer__list-link {
	@include _system.typo(paragraph, bold);

	color: _system.color(primary, green);

	transition: color 0.2s ease-in-out;

	&:hover {
		color: _system.color(primary, orange);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-footer--no-spacing {
	margin-top: 0;
}
