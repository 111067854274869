@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Elements
// =============================================================================
.c-author__image {
	object-fit: cover;
	width: _system.space(10);
	min-width: _system.space(10);
	height: _system.space(10);
	min-height: _system.space(10);
	margin-right: _system.space(3);

	border-radius: borders.$radius-round;
}

.c-author__name {
	@include _system.typo(paragraph, base);

	color: _system.color(neutrals, 20);

	transition: color 0.2s ease-in-out;
}

.c-author__function {
	@include _system.typo(paragraph, base);

	color: _system.color(primary, green);
}

.c-author__content {
	display: flex;
	flex-direction: column;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-author--large {
	padding-top: _system.space(4);

	border-top: borders.$width-default solid _system.color(neutrals, 80);

	.c-author__image {
		width: _system.space(20);
		min-width: _system.space(20);
		height: _system.space(20);
		min-height: _system.space(20);
		margin-right: _system.space(8);
	}

	.c-author__name {
		@include _system.typo(h3);

		color: _system.color(primary, orange);
	}

	&:hover {
		.c-author__name {
			color: _system.color(primary, green);
		}
	}
}
