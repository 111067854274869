@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Elements
// =============================================================================
.c-team-member__image {
	height: _system.rem(260px);

	border-radius: borders.$radius-default;

	@include _system.at("9") {
		height: _system.rem(360px);
	}
}

.c-team-member__title {
	@include _system.typo(h4);

	margin-top: _system.space(6);

	color: _system.color(primary, green);

	transition: color 0.2s ease-in-out;
}

.c-team-member__function {
	@include _system.typo(paragraph, base);

	color: _system.color(primary, green);

	transition: color 0.2s ease-in-out;
}

.c-team-member__arrow {
	transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

// =============================================================================
// Modifiers
// =============================================================================
.c-team-member {
	&:hover {
		.c-team-member__title,
		.c-team-member__function {
			color: _system.color(primary, orange);
		}

		.c-team-member__arrow {
			transform: translateX(_system.rem(5px));
		}
	}
}
