@use "sass:map";
@use "@littlemissrobot/sass-system" as _system;
@use "../settings/z-index" as z;

// =============================================================================
// Block
// =============================================================================
.c-mobile-nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: z.$mobile-nav;

	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;

	/**
	 * Fix for 100vh issue on safari mobile browsers, use min-height 100vh as
	 * fallback
	 */
	height: 100vh;
	/* stylelint-disable-next-line value-no-vendor-prefix */
	height: -webkit-fill-available;
	overflow-y: scroll;
	padding-top: _system.space(18);

	background: _system.color(neutrals, 100);
	opacity: 0;

	pointer-events: none;
	// Use visiblity to hide the menu from tab when closed
	visibility: hidden;

	@include _system.at("9") {
		display: none;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-mobile-nav__container {
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-top: _system.space(4);
	padding-bottom: _system.space(4);
}

// =============================================================================
// States
// =============================================================================
.c-mobile-nav {
	&.is-open {
		opacity: 1;

		// First apply the visiblity and afterwards, animate the opacity
		transition: opacity 0.1s ease-in-out 0.01s, visibility 0s ease-in-out;

		pointer-events: auto;
		visibility: visible;
	}
}
