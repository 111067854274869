@use "sass:map";
@use "@littlemissrobot/sass-system" as _system;
@use "@littlemissrobot/sass-container" as _container;
@use "../settings/z-index" as z;
@use "../settings/borders" as borders;
@use "../utils/reset" as reset;

$container-width: map.get(_container.$settings, "max-width");

// =============================================================================
// Block
// =============================================================================
.c-page-header {
	position: relative;

	@include _system.at("7") {
		padding: _system.space(20) 0;

		/* stylelint-disable indentation */
		background-color: _system.color(primary, pink);
		background-image: linear-gradient(
				to right,
				_system.color(neutrals, 100) 50%,
				transparent 50%
			),
			url("/assets/images/background-map.svg");
		background-repeat: no-repeat;
		background-position: right, calc(100% + #{_system.rem(360px)}) 100%;
		background-size: 100% 100%, auto 100%;
		/* stylelint-enable indentation */
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-page-header__container {
	position: relative;
}

.c-page-header__content {
	z-index: z.$base;

	padding-top: _system.space(3);

	@include _system.to("7") {
		margin-top: _system.space(-4);

		background-color: _system.color(neutrals, 100);
		border-radius: borders.$radius-default;
	}
}

.c-page-header__title {
	@include _system.typo(h1);

	color: _system.color(primary, orange);
}

.c-page-header__content-item {
	opacity: 0;
}

.c-page-header__link {
	margin-top: _system.space(4);
}

.c-page-header__description {
	@include _system.typo(paragraph, base);

	max-width: _system.space(120);
	margin-top: _system.space(4);
}

.c-page-header__image-container {
	position: relative;

	min-height: _system.rem(258px);

	@include _system.at("7") {
		position: relative;
		top: 0;
		right: 0;

		width: 100%;
		height: 100%;
		min-height: _system.rem(460px);
	}
}

.c-page-header__image-wrapper,
.c-page-header__mask-container,
.c-page-header__image {
	width: 100%;
	height: 100%;
}

.c-page-header__mask-container {
	overflow: hidden;

	transform: translateX(100%);
}

.c-page-header__image {
	transform: translateX(-100%);
}

.c-page-header__image-wrapper {
	position: absolute;
}
