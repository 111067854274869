@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-treatments-list {
	display: none;

	@include _system.at("9") {
		margin-left: _system.space(10);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-treatments-list__content {
	width: 100%;
}

.c-treatments-list__link {
	width: 100%;
	padding: _system.space(7) 0;

	border-bottom: borders.$width-default solid _system.color(primary, orange);
}

.c-treatments-list__title {
	@include _system.typo(h3);

	color: _system.color(primary, green);

	transition: color 0.2s ease-in-out;
}

.c-treatments-list__description {
	@include _system.typo(paragraph, base);

	margin-top: _system.space(4);

	color: _system.color(neutrals, 20);
}

.c-treatments-list__arrow {
	margin-left: _system.space(2);

	transition: transform 0.2s ease-in-out;
}

// =============================================================================
// States
// =============================================================================
.c-treatments-list__link {
	&:hover {
		.c-treatments-list__title {
			color: _system.color(primary, orange);
		}

		.c-treatments-list__arrow {
			transform: translateX(_system.rem(5px));
		}
	}
}

.c-treatments-list {
	&.is-active {
		display: block;
	}
}
