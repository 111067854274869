@use "@littlemissrobot/sass-system" as _system;

$radius-default: _system.rem(4px);
$radius-round: 50%;

$color-default: _system.color(neutrals, 80);
$color-orange: _system.color(primary, orange);
$color-green: _system.color(primary, green);
$color-green-opacity: _system.color(opacity, green-20);

$width-default: _system.rem(1px);
$width-thick: _system.rem(12px);

$default: $width-default solid $color-default;
$default-orange: $width-default solid $color-orange;
$default-green: $width-default solid $color-green;
$default-green-opacity: $width-default solid $color-green-opacity;
$thick-green: $width-thick solid $color-green;
