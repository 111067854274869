@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
.u-hide {
	@include _system.generate-default((
		default: true,
		at: ("4", "7", "9", "12"),
		to: ("4", "7", "9", "12"),
	)) {
		/* stylelint-disable declaration-no-important */
		display: none !important;
	}
}
// Hide on screen, but keep the display for screen readers
.u-visually-hidden {
	position: absolute;

	width: _system.rem(1px);
	height: _system.rem(1px);
	overflow: hidden;

	white-space: nowrap;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
}
