@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-button {
	@include _system.typo(label, base);

	padding: _system.space(2) _system.space(6);

	text-align: center;

	color: _system.color(neutrals, 100);
	background-color: _system.color(primary, orange);
	border-radius: borders.$radius-default;

	transition: background-color 0.2s ease-in-out;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-button--small {
	@include _system.typo(label, small);

	padding: _system.space(1) _system.space(4);
}

.c-button--secondary {
	color: _system.color(primary, orange);
	background-color: transparent;
	border: _system.rem(1px) solid _system.color(primary, orange);

	transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

// =============================================================================
// States
// =============================================================================
.c-button {
	&:hover {
		background-color: _system.color(button, hover);
	}

	&.c-button--secondary {
		&:hover {
			background-color: transparent;
			color: _system.color(button, hover);
			border-color: _system.color(button, hover);
		}
	}
}
