@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../utils/reset" as reset;

// =============================================================================
// Block
// =============================================================================
.c-language {
	position: relative;

	width: fit-content;

	background-color: _system.color(neutrals, 100);
	border: borders.$default;
	border-radius: borders.$radius-default;
}

// =============================================================================
// Elements
// =============================================================================
.c-language__trigger {
	@include reset.reset-button();

	margin: 0;
	padding: _system.space(2) _system.space(3) _system.space(2) _system.space(4);

	color: _system.color(neutrals, 10);

	transition: border-radius 0.2s ease-in-out;

	@include _system.at("9") {
		padding: _system.space(1) _system.space(2) _system.space(1)
			_system.space(3);
	}
}

.c-language__trigger,
.c-language__link {
	@include _system.typo(label);

	@include _system.at("9") {
		@include _system.typo(label, small);
	}
}

.c-language__link {
	display: block;

	color: _system.color(neutrals, 10);

	transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.c-language__icon {
	width: _system.rem(24px);
	height: _system.rem(24px);

	color: _system.color(neutrals, 50);

	transition: transform 0.2s ease-in-out;
}

.c-language__switch {
	position: absolute;
	top: _system.rem(2px);
	left: _system.rem(-1px);

	display: none;
	width: calc(100% + #{_system.rem(2px)});
	overflow: hidden;
	padding: _system.space(2) _system.space(3) _system.space(2) _system.space(4);

	background-color: _system.color(neutrals, 100);
	border: borders.$default;
	border-top-left-radius: borders.$radius-default;
	border-top-right-radius: borders.$radius-default;

	border-bottom: none;

	transform: translateY(-100%);

	pointer-events: none;

	@include _system.at("9") {
		bottom: _system.rem(2px);

		padding: _system.space(1) _system.space(2) _system.space(1)
			_system.space(3);

		border: borders.$default;
		border-bottom-left-radius: borders.$radius-default;
		border-bottom-right-radius: borders.$radius-default;

		border-top: none;

		transform: translateY(100%);
	}
}

// =============================================================================
// States
// =============================================================================
.c-language {
	&.is-open,
	&:hover {
		.c-language__trigger {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		.c-language__icon {
			transform: rotate(-180deg);
		}

		.c-language__switch {
			display: block;

			pointer-events: all;
		}
	}
}

.c-language__link {
	&:hover {
		color: _system.color(primary, orange);

		transform: translateX(_system.rem(4px));
	}
}
