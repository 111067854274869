@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-quote__text {
	p {
		@include _system.typo(quote, base);

		color: _system.color(primary, green);
	}

	strong {
		@include _system.typo(quote, bold);
	}
}
