@use "@littlemissrobot/sass-system" as _system;
@use "../settings/shadow" as shadow;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-category-list {
	@include _system.to("9") {
		display: flex;
		flex-wrap: wrap;
		margin: _system.space(-1);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-category-list__item {
	display: flex;
	align-items: center;
	padding: _system.space(3) _system.space(4);

	background-color: _system.color(neutrals, 100);
	box-shadow: shadow.$default;
	border-radius: borders.$radius-default;
	border: borders.$width-default solid _system.color(neutrals, 100);

	transition: border-color 0.2s ease-in-out;

	& + .c-category-list__item {
		@include _system.at("9") {
			margin-top: _system.space(5);
		}
	}

	@include _system.to("9") {
		margin: _system.space(2);
	}
}

.c-category-list__icon {
	display: none;
	margin-right: _system.space(4);

	filter: grayscale(100%);

	transition: filter 0.2s ease-in-out;

	@include _system.at("9") {
		display: flex;
	}
}

.c-category-list__text {
	@include _system.typo(paragraph, base);

	color: _system.color(neutrals, 20);

	transition: color 0.2s ease-in-out;
}

// =============================================================================
// Modifiers
// =============================================================================
.c-category-list__item {
	&.is-active,
	&:hover {
		border: borders.$width-default solid _system.color(primary, orange);

		cursor: pointer;

		.c-category-list__icon {
			filter: grayscale(0%);
		}

		.c-category-list__text {
			color: _system.color(primary, green);
		}
	}
}
