@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../settings/z-index" as z;
@use "../utils/reset" as reset;

// =============================================================================
// Block
// =============================================================================
.c-flyout {
	z-index: z.$foreground;

	transform-origin: top center;

	@include _system.at("9") {
		min-width: _system.rem(200px);
		padding-top: _system.space(6);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-flyout__list {
	@include reset.reset-list();

	margin: 0;

	@include _system.at("9") {
		margin: _system.rem(-16px);

		border-radius: borders.$radius-default;
		border: borders.$default;
		background-color: _system.color(neutrals, 100);
	}
}

.c-flyout__list-item {
	margin: _system.space(1);

	@include _system.at("9") {
		display: flex;
	}

	&:not(:last-child) {
		border-bottom: borders.$default;
	}
}

.c-flyout__link {
	@include _system.typo(nav);

	width: 100%;
	padding: _system.space(1) _system.space(2);

	color: _system.color(neutrals, 10);

	transition: color 0.2s ease-in-out;
}

// =============================================================================
// States
// =============================================================================
.c-flyout {
	&:hover,
	&:focus-within {
		.c-flyout__content {
			opacity: 1;

			transform: scale(1);

			pointer-events: auto;
		}
	}
}

.c-flyout__link {
	&:hover {
		color: _system.color(primary, orange);
	}
}
