@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Block
// =============================================================================
.c-nav {
	@include _system.to("9") {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-nav__list {
	flex: 1;
}

.c-nav__item {
	position: relative;
}

.c-nav__link {
	@include _system.typo(nav);

	position: relative;

	padding: _system.space(3) 0;

	color: _system.color(primary, green);

	@include _system.at("9") {
		@include _system.typo(nav);

		padding: _system.space(5) 0;

		color: _system.color(neutrals, 10);
	}

	&::after {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: _system.rem(2px);

		background-color: _system.color(primary, orange);

		transform: scaleX(0);
		transform-origin: left;
		transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
	}
}

.c-nav__button {
	width: 100%;
	margin-top: _system.space(4);

	@include _system.at("9") {
		width: auto;
		margin-top: 0;
		margin-left: _system.space(4);
	}
}

.c-nav__flyout {
	position: relative;

	opacity: 1;

	@include _system.at("9") {
		position: absolute;
		bottom: 0;
		left: 0;

		opacity: 0;

		transform: translateY(100%);
		transition: opacity 0.2s ease-in-out;

		pointer-events: none;
	}
}

.c-nav__link-icon {
	width: _system.rem(24px);
	margin-left: _system.space(1);

	color: _system.color(neutrals, 50);

	transition: transform 0.2s ease-in-out;

	@include _system.to("9") {
		display: none;
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-nav__link--secondary {
	@include _system.typo(p, base);
}

// =============================================================================
// States
// =============================================================================
.c-nav__item {
	&:hover,
	&:focus-within {
		@include _system.at("7") {
			.c-nav__link {
				&::after {
					transform: scaleX(1);
				}
			}

			.c-nav__flyout {
				opacity: 1;

				pointer-events: auto;
			}

			.c-nav__link-icon {
				transform: rotate(-180deg);
			}
		}
	}
}
