@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;
@use "../settings/z-index" as z;

$image-height-desktop: _system.rem(650px);
$image-height-mobile: _system.rem(300px);
$overlap: _system.rem(300px);

// =============================================================================
// Block
// =============================================================================
.c-detail-header {
	position: relative;

	margin-top: _system.space(5);
	margin-bottom: _system.space(8);

	@include _system.at("9") {
		min-height: $image-height-desktop;
		margin-top: _system.space(10);
		margin-bottom: _system.space(16);

		&::before {
			content: "";

			display: block;
			min-height: calc(#{$image-height-desktop} - #{$overlap});
		}
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-detail-header__image-container {
	position: relative;
	z-index: z.$background;

	@include _system.to("9") {
		min-height: _system.rem(258px);
	}

	@include _system.at("9") {
		position: absolute;
		top: 0;
		right: 0;

		width: 100%;
		height: 100%;
	}
}

.c-detail-header__image-wrapper,
.c-detail-header__mask-container,
.c-detail-header__image {
	width: 100%;
	height: 100%;
}

.c-detail-header__mask-container {
	overflow: hidden;

	border-radius: borders.$radius-default;

	transform: translateX(100%);
}

.c-detail-header__image {
	max-height: $image-height-mobile;

	transform: translateX(-100%);

	pointer-events: none;

	@include _system.at("7") {
		max-height: $image-height-desktop;
	}
}

.c-detail-header__container {
	z-index: z.$base;

	margin-top: _system.space(-2);

	@include _system.at("7") {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		min-height: $overlap;
		margin-top: 0;
	}
}

.c-detail-header__content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: _system.space(11);

	background-color: _system.color(neutrals, 100);
	border-top-left-radius: borders.$radius-default;
	border-top-right-radius: borders.$radius-default;

	@include _system.at("7") {
		padding-top: _system.space(14);
	}
}

.c-detail-header__date {
	@include _system.typo(paragraph, base);

	color: _system.color(neutrals, 20);
}

.c-detail-header__content {
	opacity: 0;
}

.c-detail-header__meta {
	margin-bottom: _system.space(2);
}

.c-detail-header__label {
	@include _system.typo(label, base);

	display: block;

	color: _system.color(primary, orange);
}

.c-detail-header__date {
	@include _system.typo(paragraph, base);

	display: block;

	color: _system.color(neutrals, 20);
}

.c-detail-header__circle {
	margin-top: _system.space(1);

	color: _system.color(primary, orange);
}

.c-detail-header__title {
	@include _system.typo(h1);

	text-align: center;

	color: _system.color(primary, green);
}

.c-detail-header__intro {
	margin-top: _system.space(10);

	p,
	strong {
		@include _system.typo(paragraph, bold);

		text-align: center;

		color: _system.color(neutrals, 0);
	}
}

// =============================================================================
// Modifiers
// =============================================================================
.c-detail-header--simple {
	min-height: unset;

	&::before {
		display: none;
	}
}
