@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-accordeon {
	padding: _system.space(15) 0;

	background-color: _system.color(primary, pink);

	@include _system.at("9") {
		padding: _system.space(25) 0;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-accordeon__title {
	@include _system.typo(h1);

	color: _system.color(primary, green);
}

.c-accordeon__text {
	@include _system.typo(paragraph, base);

	margin-top: _system.space(3);

	color: _system.color(neutrals, 10);
}

.c-accordeon__content {
	margin-bottom: _system.space(5);
}
