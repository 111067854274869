// =============================================================================
// Mixins
// =============================================================================
@mixin reset-list {
	margin: 0;
	padding: 0;

	list-style-type: none;
}

@mixin reset-link {
	text-decoration: none;
}

@mixin reset-button {
	padding: 0;

	border-radius: 0;
	background: none;
	border: none;

	cursor: pointer;
}
