// stylelint-disable selector-max-universal

@use "@littlemissrobot/sass-system" as _system;

// prettier-ignore
.u-stack {
	@include _system.generate-value((
		value: (2, 4, 6,),
		at: (),
		to: (
			"7": (3,),
		),
	)) using ($item) {
		& > * + * {
			margin-top: _system.space($item);
		}
	}
}

// prettier-ignore
.u-inline {
	@include _system.generate-value((
		value: (2, 4, 8,),
		at: (
			"7": (4,),
			"9": (6,),
		),
		to: (),
	)) using ($item) {
		& > * + * {
			margin-left: _system.space($item);
		}
	}
}

// prettier-ignore
.u-inline-wrap {
	@include _system.generate-value((
		value: (2, 4),
		at: (),
		to: (),
	)) using ($item) {
		$space: _system.space($item) * 0.5;

		margin-right: $space * -1;
		margin-left: $space * -1;

		& > * {
			display: inline-block;
			margin-right: $space;
			margin-left: $space;
		}
	}
}

// prettier-ignore
$margin: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-x: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-y: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-top: (
	value:(2, 4, 14),
	at: (),
	to: (),
);

// prettier-ignore
$margin-bottom: (
	value:(14),
	at: (),
	to: (),
);

// prettier-ignore
$margin-left: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$margin-right: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding-x: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding-y: (
	value:(4, 8),
	at: (),
	to: (),
);

// prettier-ignore
$padding-top: (
	value:(4,),
	at: (),
	to: (),
);

// prettier-ignore
$padding-bottom: (
	value:(4),
	at: (),
	to: (),
);

// prettier-ignore
$padding-left: (
	value:(),
	at: (),
	to: (),
);

// prettier-ignore
$padding-right: (
	value:(),
	at: (),
	to: (),
);
