@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-job-offer {
	border-bottom: _system.rem(1px) solid _system.color(neutrals, 80);

	&:first-child {
		border-top: _system.rem(1px) solid _system.color(neutrals, 80);
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-job-offer__link {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: _system.space(7) _system.space(2);

	text-decoration: none;
}

.c-job-offer__header {
	display: flex;
}

.c-job-offer__title {
	@include _system.typo(h4);

	color: _system.color(primary, green);

	transition: color 0.2s ease-in-out;
}

.c-job-offer__text {
	@include _system.typo(small, base);

	margin-top: _system.space(2);

	color: _system.color(neutrals, 10);
}

.c-job-offer__icon {
	margin-right: _system.space(2);
}

.c-job-offer__arrow {
	width: _system.space(4);
	height: _system.space(4);
	margin-left: _system.space(4);

	color: _system.color(primary, orange);

	transition: transform 0.6s cubic-bezier(0.16, 1, 0.3, 1);
}

// =============================================================================
// States
// =============================================================================
.c-job-offer__link {
	&:hover {
		.c-job-offer__title {
			color: _system.color(primary, orange);
		}

		.c-job-offer__arrow {
			transform: translateX(_system.space(2));
		}
	}
}
