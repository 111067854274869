@use "@littlemissrobot/sass-system" as _system;

// =============================================================================
// Block
// =============================================================================
.c-prices {
	padding-top: _system.space(20);

	/* stylelint-disable length-zero-no-unit */
	background-image: linear-gradient(
		to bottom,
		_system.color(primary, green) 0px 320px,
		transparent 320px
	);
	/* stylelint-enable length-zero-no-unit */
}

// =============================================================================
// Block
// =============================================================================
.c-prices__title {
	color: _system.color(neutrals, 100);
}

.c-prices__items {
	margin-top: _system.space(15);
}
