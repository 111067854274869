@use "@littlemissrobot/sass-system" as _system;
@use "../settings/borders" as borders;

// =============================================================================
// Settings
// =============================================================================
$easing: cubic-bezier(0.16, 1, 0.3, 1);

// =============================================================================
// Block
// =============================================================================
.c-accordeon-item {
	padding: _system.space(4) 0;

	& + .c-accordeon-item {
		border-top: borders.$default-green-opacity;
	}

	&:last-child {
		border-bottom: borders.$default-green-opacity;
	}
}

// =============================================================================
// Elements
// =============================================================================
.c-accordeon-item__header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	cursor: pointer;
}

.c-accordeon-item__title {
	flex: 1;
	margin-right: _system.space(4);
	margin-left: _system.space(4);

	text-align: left;

	color: _system.color(primary, orange);

	@include _system.at("9") {
		margin-right: _system.space(8);
		margin-left: _system.space(8);
	}
}

.c-accordeon-item__subtitle {
	@include _system.typo(h4, thin);

	margin-bottom: _system.space(3);

	color: _system.color(primary, green);
}

.c-accordeon-item__icon-button {
	position: relative;

	width: _system.rem(40px);
	height: _system.rem(40px);

	border: borders.$default-green;
	border-radius: borders.$radius-default;
}

.c-accordeon-item__content {
	display: none;
	padding-left: calc(#{_system.rem(35px)} + #{_system.space(4)});

	@include _system.at("9") {
		padding-left: calc(#{_system.rem(50px)} + #{_system.space(8)});
	}
}

.c-accordeon-item__text {
	color: _system.color(neutrals, 10);

	h2,
	h3 {
		@include _system.typo(h4, thin);

		margin: _system.space(4) 0 _system.space(2);

		color: _system.color(primary, green);
	}
}

.c-accordeon-item__link {
	margin-top: _system.space(4);
}

.c-accordeon-item__line {
	position: absolute;
	top: 50%;
	left: 50%;

	display: block;
	width: _system.space(4);
	height: _system.rem(2px);

	background-color: _system.color(primary, green);

	transform: translateX(-50%);
	transform-origin: center;
	transition: transform 0.6s $easing;
}

.c-accordeon-item__line--second {
	transform: translateX(-50%) rotate(90deg);
}

// =============================================================================
// States
// =============================================================================
.c-accordeon-item {
	&.is-open {
		.c-accordeon-item__text,
		.c-accordeon-item__button {
			opacity: 1;
		}

		.c-accordeon-item__content {
			display: block;
		}

		.c-accordeon-item__line--first {
			transform: translateX(-50%) rotate(180deg);
		}

		.c-accordeon-item__line--second {
			transform: translateX(-50%) rotate(180deg);
		}
	}
}
